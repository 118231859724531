$transition-duration-short: 0.1s;
$transition-duration-medium: 0.25s;
$transition-duration-long: 0.5s;

$transition-easing: ease-in-out;
$transition: $transition-duration-medium $transition-easing;
$transition-short: $transition-duration-short $transition-easing;
$transition-long: $transition-duration-long $transition-easing;

.transition {
  transition: $transition-duration-medium $transition-easing;
}

.fade-in {
  animation: fade-in $transition-duration-medium $transition-easing
    $transition-duration-medium;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
