@import 'settings';
@import '~@sanctucompu/basement/src/index';

body {
  background: color('tan');
  font-family: $s;
}

a {
  color: color('black');
  text-decoration: none;
  // border: 1px dashed transparent;
  // border-bottom-color: color('red');
  padding: 0 .25rem;
  transition: .3s border;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 65%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: $transition-long;
    @include radial-gradient-item(0%, 0%, 50%, rgba(0, 0, 0, .3));
  }

  &:hover {
    // border-color: color('black');

    &::after {
      opacity: 1;
    }
  }
}

.title {
  font-style: italic;
  font-size: 19px;
  line-height: 1.35em;
  letter-spacing: 0px;
}

.site-title {
  font-size: 12px;
  position: relative;
  display: inline-block;
  padding-right: .25rem;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 50;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: -1;
  //   @include dithered-gradient(0%, 0%, 100%, color('red'));
  // }
}

.subtitle { 
  font-size: 19px;
  line-height: 1.45em;

  // @include media('md-up') {
  //   font-size: 23px;
  // }
}

.headline-override {
  font-size: 1rem;
  font-style: normal;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 65%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    @include radial-gradient-item(0%, 0%, 50%, rgba(0, 0, 0, .3));
  }
}

.small-headline {
  font-size: 12px;
}

.min-height-100 {
  min-height: 100vh;
}

.vh100 {
  height: 100vh;
}

body {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    @include dithered-gradient(100%, 00%, 100%, tan);
  }
}

body *::selection {
  background: black;
  color: tan;
}
